<template>
  <div class="w-full content_page" style="background: #F6F6F6;">
    <div class="flex justify-center w-full pc_show">
      <div class="text-left text-gray-500 path sm:py-4 conent_box">
        <navbar-text :navbarList="navbarList"></navbar-text>
      </div>
    </div>
    <div class="flex justify-center">
      <!-- pc端 -->
      <div class="flex justify-between mb-6 page_box pc_show">
        <div class="bg-white page_left">
          <div class="flex items-center justify-start drive_title_box">
            <div class="items-center drive_title">玩机宝典</div>
          </div>
          <ul class="sm:pt-4">
            <li v-for="(item, index) in list" :key="index" :class="current == index ? 'text-primary' : ''"
              class="text-left cursor-pointer sm:py-2 hover:text-primary" @click="changeIndex(item, index)">
              {{ item.tidingsName }}
            </li>
          </ul>
          <!-- <div class="mt-8 text-left">联系客服</div> -->
        </div>
        <div class="pb-4 bg-white page_right">
          <div class="text-left text-gray-400 current_title sm:py-6 sm:font-bold">
            {{ list[current].tidingsName }}
          </div>
          <div v-if="pageType == 'list'">
            <div class="flex flex-wrap w-full px-8 current_content sm:pt-4" v-if="contentList.length > 0">
              <ul class="w-full">
                <li class="flex items-center justify-between w-full cursor-pointer list_item sm:py-2"
                  v-for="item in contentList" :key="item.tidingsId">
                  <div class="flex items-center" style="width:90%" @click="goDetail(item)">
                    <div class="mr-2 rounded-full cirle" style="width:4px;height:4px;background:black;"></div>
                    <div class="text-left content_text">
                      {{ item.tidingsName }}
                    </div>
                  </div>
                  <div @click="collectionOperation(item)" v-if="item.ifCollect == false" class="flex items-center">
                    收藏
                  </div>

                  <div class="flex items-center text-primary" @click="collectionOperation(item)"
                    v-if="item.ifCollect == true">
                    已收藏
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex flex-wrap w-full px-8 current_content sm:pt-4" v-else>
              <div class="text-gray-400">暂无数据</div>
            </div>
          </div>
          <!-- <div class="py-3">
                        <el-pagination layout="prev, pager, next" :total="1000"></el-pagination>
                    </div>-->
          <div class="w-full px-8 current_content sm:pt-4" v-else>
            <div class="w-full text-left text-gray-500 cursor-pointer" @click="pageType = 'list'">
              &#60; 点击返回
            </div>
            <div v-html="contentData"></div>
          </div>
        </div>
      </div>

      <!-- 手机端 -->
      <div class="flex justify-between mb-6 page_box phone_show">
        <div class="w-full">
          <div class="relative flex items-center justify-center drive_title_box">
            <div class="items-center font-bold drive_title">新闻中心</div>
            <!-- <div class="absolute right-0 text-xs text-primary">联系客服</div> -->
          </div>
          <!-- <div class="text-left text-gray-500 path">首页-内容</div> -->
          <div class="flex items-center w-full scorll_box">
            <div v-for="(item, index) in list" :key="index" :class="current == index ? 'text-primary' : ''"
              class="pr-4 font-bold text-left" @click="changeIndex(item, index)">
              {{ item.tidingsName }}
            </div>
          </div>
          <div v-if="pageType == 'list'">
            <div class="w-full current_content" v-if="contentList.length > 0">
              <ul class="w-full">
                <li
                  class="flex items-center justify-between w-full py-2 text-left text-gray-500 cursor-pointer list_item"
                  v-for="item in contentList" :key="item.tidingsId">
                  <div class="flex items-center" style="width:90%" @click="goDetail(item)">
                    <div class="mr-2 rounded-full cirle" style="width:4px;height:4px;background:black;"></div>
                    <div class="text-left content_text">
                      {{ item.tidingsName }}
                    </div>
                  </div>
                  <div class="text-primary" @click="collectionOperation(item)" v-if="item.ifCollect == false">
                    收藏
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex flex-wrap w-full current_content sm:pt-4" v-else>
              <div class="text-gray-400">暂无数据</div>
            </div>
          </div>
          <div v-else class="w-full current_content">
            <div class="w-full text-left text-gray-500 cursor-pointer" @click="pageType = 'list'">
              &#60; 点击返回
            </div>
            <div v-html="contentData"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      current: 0,
      tabs: ["品牌咨询", "疑难解答", "产品快报"],
      list: [],
      contentList: [],
      pageType: "list", // 页面类型 list:展示列表   content:展示详情
      contentData: null,
      navbarList: [{ title: "内容", path: '' }]
    };
  },
  computed: {
    ...mapGetters(["getLoginStatus", "getUserInfo"]),
  },
  created() {
    this.findTidings();
    if (this.getLoginStatus) {
      this.$store.dispatch("aquireUserInfo");
    }

    this.$EventBus.$on("contentOperation", (id) => {
      let index = this.contentList.findIndex((item) => {
        return item.tidingsId == id;
      });
      index > -1 ? (this.contentList[index].ifCollect = true) : "";
    });
  },
  methods: {
    changeIndex(item, index) {
      this.current = index;
      this.findTidings(item.code);
    },
    findTidings(code) {
      this.pageType = "list";
      let url = this.$requestPath.findTidings;
      if (code) {
        url = url + `?code=${code}`;
      }
      this.$request({
        method: "get",
        url,
      })
        .then((res) => {
          //   console.log("新闻中心信息展示", res);
          if (res.code == 0) {
            if (code) {
              this.contentList = [];
              this.contentList.push(...res.data);
            } else {
              this.list = [];
              this.list.push(...res.data);
              this.findTidings(this.list[0].code);
            }
          } else {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: "获取数据失败",
            });
          }
        })
        .catch((error) => {
          console.log("新闻中心信息展示", error);
        });
    },
    // 内容详情
    goDetail(item) {
      // console.log("item", item)
      // 当用户登录的时候就记录历史
      if (this.getLoginStatus) {
        this.$request({
          method: "post",
          url: this.$requestPath.recordHistory,
          data: {
            recordId: item.tidingsId,
            userId: this.getUserInfo.userId,
          },
        })
          .then((res) => {
            console.log("记录", res);
          })
          .catch((error) => {
            console.log("记录报错", error);
          });
      }
      // item.ifJump = false
      // item.contentData = "adlsjkflajfdklja "
      if (!item.ifJump) {
        this.pageType = "content";
        this.contentData = item.contentData;
      } else {
        window.open(item.link);
      }
    },
    // 收藏
    collectionOperation(item) {
      this.$store.dispatch("collectOpration", {
        type: 2,
        relationId: item.tidingsId,
        $EventBus: this.$EventBus,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content_page {
  min-height: var(--min-height);
}

.conent_box {
  width: 100%;
  max-width: 75rem;
  padding-left: var(--margin-base);
  padding-right: var(--margin-base);
}

.page_box {
  width: 100%;
  max-width: 75rem;
  // height: 3000px;
  padding: 0 var(--margin-base);

  .page_left {
    width: 13.75rem;
    padding: 0 1.875rem;
    // max-width: 12.5rem;
  }

  .page_right {
    width: calc(100% - 15rem);
  }
}

.current_title {
  border-bottom: 0.0625rem solid var(--border-light);
}

.list_item {
  list-style-type: disc !important;
}

.content_text {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 640px) {
  .phone_show {
    display: block;
  }

  .pc_show {
    display: none;
  }

  .drive_title_box {
    margin: 0.625rem 0px;

    img {
      width: 1.25rem;
    }

    .drive_title {
      font-size: 0.9375rem;
      display: flex;
      padding-top: 0.0625rem;
    }
  }

  .path {
    font-size: 0.875rem;
    margin-bottom: 0.3125rem;
  }

  .scorll_box {
    height: 1.875rem;
    line-height: 1.875rem;
    // overflow-x: scroll;
    // white-space: nowrap;
    // font-weight: bold;
    // -ms-overflow-style: none; /* IE 10+ */
    // scrollbar-width: none; /* Firefox */
    // ::-webkit-scrollbar {
    //     display: none; /* Chrome Safari */
    // }
  }

  .current_content {
    margin-top: 0.625rem;

    .item {
      font-size: 0.8125rem;
    }
  }

  .item_box {
    margin-bottom: 0.25rem;

    .icon_box {
      bottom: 0.25rem;
      right: 0.25rem;

      img {
        width: 0.75rem;
        margin-left: 0.3125rem;
      }
    }
  }

  .list_item {
    list-style-type: disc !important;
  }
}

@media screen and (min-width: 640px) {
  .phone_show {
    display: none;
  }

  .pc_show {
    display: flex;
  }

  .drive_title_box {
    margin: 0.625rem 0px;

    img {
      width: 1.4375rem;
    }

    .drive_title {
      font-size: 1rem;
      display: flex;
      padding-top: 0.0625rem;
    }
  }

  .current_title {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    font-size: 1.5625rem;
  }

  // .current_content {
  // margin-left: 1.875rem;
  // margin-right: 1.875rem;
  // ul .list_item {
  // margin-left: 1.875rem;
  // margin-right: 1.875rem;
  // }
  // }
  .item_box {
    margin-bottom: 0.25rem;

    .icon_box {
      top: 0.625rem;
      left: 0.625rem;

      img {
        width: 0.9375rem;
      }

      div {
        font-size: 0.1875rem;
        margin-left: 0.125rem;
      }
    }
  }
}
</style>
